@charset "utf-8";

@use "../_colors";
@use "../_text";


.page-system.page-people {

  .ph_sys img {
    aspect-ratio: 3/4;
  }

  /*  Main と Side
  *----------------------------------- */

  @media print, screen and (min-width: 761px) {
    /* for pc */
    &.page-system-index .list-page__list {
      max-width: 1100px;
      width: 100%;
    }
  }
  @media screen and (max-width: 979px) {
    /* for sp iPad */
    &.page-system-index .list-page__list {
      width: 100%;
      float: none;
    }
  }
  @media screen and (max-width: 480px) {
    /* for sp Device Only */
    &.page-system-index aside.list-page__side {
      width: 100%;
      display: block;
      margin-top: 16%;
      .widget {
        width: 100%;
      }
    }
  }

  .list-page__main {
    @media print, screen and (max-width: 760px) {
      margin-top: 8vw;
    }
  }



  /* list-page__list
  *----------------------------------- */

  &.page-system-index .list-page__list h3 {
    font-size: 18px;
    padding: 12px 20px;
    color: colors.$light-text;
    background: colors.$light-blue;
    text-align: center;
    margin-bottom: 30px;
  }
  @media print, screen and (max-width: 760px) {
    &.page-system-index .list-page__list h3 {
      margin-bottom: 6%;
    }
  }

  &.page-system-index .list-page__list .l-list .p-item {
    float: left;
    border: none;
    a {
      padding: 0;
    }
    &__post-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 1.4;
      margin-bottom: 10px;
      max-height: 60px;
      @media print, screen and (max-width: 760px) {
        font-size: 4.2vw;
        margin-bottom: 4%;
        max-height: 13vw;
      }
    }
    &__post-name {
      font-weight: 500;
      font-size: 18px;
      line-height: 1;
      margin-bottom: 8px;
      max-height: 40px;
      @media print, screen and (max-width: 760px) {
        font-size: 3.8vw;
        margin-bottom: 3%;
        max-height: 8vw;
      }
    }
    &__post-company {
      font-size: 13px;
      line-height: 1.7;
      font-weight: 500;
      max-height: 50px;
      @media print, screen and (max-width: 760px) {
        font-size: 3vw;
        line-height: 1.4;
        max-height: 9vw;
      }
    }
  }

  //3columnの場合 ※SPは2カラムと同じ
  @media print, screen and (min-width: 761px) {
    &.page-system-index .list-page__list .l-list .p-item {
      &__inner {
        margin-top: 8px;
      }
    }
    &.page-system-index .list-page__list .l-column_03 .p-item {
      width: 32%;
      margin: 0 auto 40px;
      margin-right: 2%;
      &__inner {
        // float: none;
        width: 100%;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:nth-child(3n+1) {
        clear: both;
      }
    }
  }



  /*  page-system-detail
  *----------------------------------- */

  &.page-system-detail .l-list .p-item {
    margin-bottom: 80px;
    @media print, screen and (max-width: 760px) {
      margin-bottom: 14%;
    }
    &__photo {
      width: 450px;
      @media print, screen and (max-width: 760px) {
        width: 80%;
      }
    }
    &__icons-category {
      text-align: center;
    }
    &__post-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 1.5;
      margin-bottom: 18px;
      text-align: center;
      @media print, screen and (max-width: 760px) {
        font-size: 6vw;
        margin-bottom: 4%;
      }
    }
    &__post-name {
      font-weight: 500;
      font-size: 20px;
      line-height: 1;
      text-align: center;
      @media print, screen and (max-width: 760px) {
        font-size: 4.8vw;
      }
    }
    &__post-company {
      font-size: 14px;
      line-height: 1.7;
      font-weight: 500;
      margin-bottom: 4px;
      @media print, screen and (max-width: 760px) {
        font-size: 3.6vw;
        line-height: 1.4;
        margin-bottom: 2%;
      }
    }
    &__post-year {
      font-size: 14px;
      line-height: 1.7;
      font-weight: 500;
      margin-bottom: 6px;
      @media print, screen and (max-width: 760px) {
        font-size: 3.6vw;
        line-height: 1.4;
        margin-bottom: 3%;
      }
    }
  }

  &.page-system-detail .l-unit {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: stretch;
    margin-bottom: 150px;
    @media print, screen and (max-width: 760px) {
      display: flex;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 18%;
      display: flex;
      flex-wrap: wrap-reverse;
    }
    &__txt-box {
      width: calc(100% - 550px);
      @media print, screen and (max-width: 760px) {
        width: 100%;
      }
    }
    &__ph {
      width: 500px;
      @media print, screen and (max-width: 760px) {
        width: 100%;
        margin-bottom: 8%;
      }
    }
    &__tit {
      font-size: 26px;
      font-weight: 600;
      margin-bottom: 20px;
      line-height: 1.4;
      border-bottom: 2px solid colors.$light-blue;
      padding-bottom: 10px;
      color: colors.$light-blue;
      @media print, screen and (max-width: 760px) {
        font-size: 6vw;
        margin-bottom: 5%;
        padding-bottom: 3%;
      }
    }
    @media print, screen and (min-width: 761px) {
      &:nth-child(2n+1) {
        flex-direction: row-reverse;
        .l-unit__ph {
          margin-right: 50px;
          margin-left: 0;
        }
      }
      &:nth-child(2n) {
        .l-unit__ph {
          margin-right: 0;
          margin-left: 50px;
        }
      }
    }
    &.no-image {
      .l-unit__txt-box {
        width: 100%;
      }
    }
  }


  &.page-system-detail {
    .people {
      margin-bottom: 0;
    }
    .interview__read-more.sp {
      display: none;
    }
  }


  /*  l-cmn-member
  *----------------------------------- */

  .l-cmn-member {
    background: #ebf3fa;
    padding: 75px 0 80px;
    @media print, screen and (max-width: 760px) {
      padding: 13% 5%;
      margin-top: 15vw;
    }
    .l-member {
      &__header {
        text-align: center;
        margin-bottom: 25px;
        @media print, screen and (max-width: 760px) {
          margin-bottom: 3%;
        }
        .title {
          font-family: text.$font01;
          font-size: 66px;
          font-weight: 600;
          line-height: 1;
          color: colors.$blue;
          @media print, screen and (max-width: 760px) {
            font-size: 11.5vw;
          }
        }
        .subtitle {
          font-size: 17px;
          font-weight: 500;
          @media print, screen and (max-width: 760px) {
            font-size: 4vw;
            margin-top: -2%;
          }
        }
      }
      &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 1200px;
        margin: 0 auto 50px;
        @media print, screen and (max-width: 760px) {
          width: 100%;
          margin: 0px auto 12%;
          padding-bottom: 12%;
        }
        .p-item {
          width: 380px;
          margin-right: 30px;
          margin-bottom: 0;
          &:last-child {
            margin-right: 0;
          }
          @media print, screen and (max-width: 760px) {
            width: 100%;
            margin-right: 0;
          }
          &__inner {
            margin-top: 15px;
            @media print, screen and (max-width: 760px) {
              margin-top: 3%;
            }
          }
          &__post-title {
            font-weight: 700;
            font-size: 18px;
            line-height: 1.4;
            margin-bottom: 10px;
            max-height: 60px;
            @media print, screen and (max-width: 760px) {
              font-size: 4.2vw;
              margin-bottom: 4%;
              max-height: 13vw;
            }
          }
          &__post-name {
            font-weight: 700;
            font-size: 18px;
            line-height: 1;
            margin-bottom: 8px;
            max-height: 40px;
            @media print, screen and (max-width: 760px) {
              font-size: 3.8vw;
              margin-bottom: 2%;
              max-height: 8vw;
            }
          }
          &__post-company {
            font-size: 13px;
            line-height: 1.7;
            font-weight: 500;
            max-height: 50px;
            @media print, screen and (max-width: 760px) {
              font-size: 3vw;
              line-height: 1.4;
              max-height: 9vw;
            }
          }
        }
      }
    }
  }


}/* end -- .page-system.page-people */


