@charset "utf-8";

@use "_colors";
@use "_text";

@use "layout/_wp_article";
@use "layout/_system_common";

@use "layout/_system_news";
@use "layout/_system_people";
@use "layout/_system_faq";
@use "layout/_system_job";
@use "layout/_system_event";
@use "layout/_system_voice";
@use "layout/_system_welfare";

.ph_sys {
  line-height: 0;
  width: 100%;
  height: auto;
  img {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }
}
