@charset "utf-8";

@use "../_colors";
@use "../_text";


.page-system.page-voice {

  /*  cmn
  *----------------------------------- */

  &.page-system-index .l-main,
  &.page-system-detail .l-main {
    @media print, screen and (min-width: 761px) {
      background: colors.$detail-background;
    }
  }

  .voice-list__tag.p-item__icons {
    margin-bottom: 5px;
    display: flex;
    flex-flow: wrap;
    gap: 3px 1em;
    @media print, screen and (max-width: 760px) {
      justify-content: center;
    }
    li.icon {
      font-size: 1.4rem;
      color: colors.$accent;
      background: none !important;
      padding: 0;
      padding-left: 10px;
      position: relative;
      @media print, screen and (max-width: 760px) {
        font-size: 3.5897435897vw;
      }
      &:before {
        position: absolute;
        z-index: 1000;
        content: "#";
        top: 0;
        left: 0;
      }
    }
  }



  /*  page-system-index
  *----------------------------------- */

  &.page-system-index .list-page__main {
    @media print, screen and (min-width: 761px) {
      width: 1100px;
      background: colors.$light-background;
      padding: 0;
    }
    @media print, screen and (max-width: 760px) {
      margin: 10% auto 20%;
    }
  }

  &.page-system-index .list-page__list {
    @media print, screen and (min-width: 761px) {
      max-width: 850px;
      width: 78%;
      float: left;
    }
    @media screen and (max-width: 979px) {
      /* for sp iPad */
      width: 100%;
      float: none;
    }
  }

  &.page-system-index aside.list-page__side {
    @media print, screen and (min-width: 761px) {
      max-width: 180px;
      width: 18%;
      float: right;
    }
    @media screen and (max-width: 979px) {
      width: 100%;
      float: none;
      margin: 0 auto;
      max-width: 100%;
      justify-content: space-between;
      display: flex;
    }
    @media screen and (max-width: 480px) {
      width: 100%;
      display: block;
      margin-top: 16%;
    }
    .widget {
      @media screen and (max-width: 979px) {
        width: 49%;
      }
      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }
  }

  &.page-system-index .list-page__list h3 {
    font-size: 18px;
    padding: 12px 20px;
    color: colors.$title-text;
    border-top: 1px solid colors.$title-border;
    border-bottom: 1px solid colors.$title-border;
    text-align: center;
    margin-bottom: 30px;
    @media print, screen and (max-width: 760px) {
      margin-bottom: 6%;
    }
  }

  &.page-system-index .list-page__list .l-list .p-item {
    float: left;
    border: none;
    a {
      padding: 0;
    }
    &__inner {
      @media print, screen and (min-width: 761px) {
        margin-top: 12px;
      }
    }
    &__photo {
      &.ph_sys img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
    }
  }

  //PC 3column（※SPは2カラムと同じ）
  @media print, screen and (min-width: 761px) {
    &.page-system-index .list-page__list .l-column_03 .p-item {
      width: 32%;
      margin: 0 auto 40px;
      margin-right: 2%;
      &__photo {
        float: none;
      }
      &__inner {
        float: none;
        width: 100%;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:nth-child(3n+1) {
        clear: both;
      }
    }
  }



  /*  page-system-detail
  *----------------------------------- */

  &.page-system-detail .l-main {
    .list-page__main {
      @media print, screen and (min-width: 761px) {
        width: 1000px;
        padding: 0 90px;
        background: colors.$light-background;
      }
    }
    .l-list {
      .p-item__photo {
        @media print, screen and (min-width: 761px) {
          max-width: 540px;
          margin-left: auto;
          margin-right: auto;
        }
        &.ph_sys img {
          width: 100%;
          aspect-ratio: 1/1;
          object-fit: cover;
        }
      }
      .p-item__inner {
        text-align: center;
      }
      .p-item__icons-category {
        text-align: center;
      }
    }

  }
  &.page-system-detail .l-other_article {
    .p-item__photo {
      &.ph_sys img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
    }
  }

}/* end -- .page-system.l-voice */




/*  詳細写真横並び
*----------------------------------- */

@media print, screen and (min-width: 761px) {
  .page-blog.page-system-detail .l-list .p-item {
    display: flex;
    &__photo {
      width: 47%;
      margin: 0 3% 0 0;
    }
     &__inner {
      width: 50%;
      margin-top: 20px;
    }
  }
}
