@charset "utf-8";

@use "../_colors";
@use "../_text";



.system_article {

  /*
  YUI 3.5.0 (build 5089)
  Copyright 2012 Yahoo! Inc. All rights reserved.
  Licensed under the BSD License.
  http://yuilibrary.com/license/
  */
  html {
    color: colors.$dark-text;
    background: #fff;
  }

  body,
  div,
  dl,
  dt,
  dd,
  ul,
  ol,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  code,
  form,
  fieldset,
  legend,
  input,
  textarea,
  p,
  blockquote,
  th,
  td {
    margin: 0;
    padding: 0;
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;
  }

  fieldset,
  img {
    border: 0;
  }

  address,
  caption,
  cite,
  code,
  dfn,
  em,
  strong,
  th,
  var {
    font-weight: normal;
    font-style: normal;
  }

  ol,
  ul {
    list-style: none;
  }

  caption,
  th {
    text-align: left;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
    font-weight: normal;
  }

  q:before,
  q:after {
    content: "";
  }

  abbr,
  acronym {
    font-variant: normal;

    border: 0;
  }

  sup {
    vertical-align: text-top;
  }

  sub {
    vertical-align: text-bottom;
  }

  input,
  textarea,
  select {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;

    z-index: auto;
  }

  input,
  textarea,
  select {
    *font-size: 100%;
  }

  legend {
    color: #000;
  }

  #yui3-css-stamp.cssreset {
    display: none;
  }

  main,
  aside {
    display: block;
  }

  p {
    color: colors.$dark-text;
    font-size: 14px !important;
    font-weight: normal !important;
    line-height: 1.6 !important;
    margin-bottom: .6em !important;
    min-height: 1.4em;

    &::after {
      display: block;
      visibility: hidden;
      clear: both;
      height: 0;
      content: "";
    }
  }

  ul {
    color: colors.$dark-text;
    font-size: 14px !important;
    font-weight: normal !important;
    line-height: 1.4 !important;
    margin-bottom: .6em !important;
    padding-left: 1.5em !important;
    list-style: disc !important;
  }

  ol {
    color: colors.$dark-text;
    font-size: 14px !important;
    font-weight: normal !important;
    line-height: 1.4 !important;
    margin-bottom: .6em !important;
    padding-left: 1.5em !important;
    list-style: decimal !important;
  }

  a {
    color: colors.$color-a;
    text-decoration: underline !important;
    &:hover {
      text-decoration: none !important;
    }
    &:active {
      text-decoration: underline !important;
    }
  }

  .aligncenter {
    clear: both !important;
    display: block !important;
    margin: 0 auto !important;
  }
  img.aligncenter {
    clear: both !important;
    display: block !important;
    margin: 0 auto !important;
  }

  .alignleft {
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
    clear: both;
  }

  .alignright {
    float: right;
    margin-left: 20px;
    margin-bottom: 20px;
    clear: both;
  }

  .wp-caption-text {
    text-align: center;
  }

  strong,
  em {
    font-weight: bold !important;
  }

  h1 {
    clear: both;
    font-weight: bold;
    line-height: 1.4;
    margin: 2.4em 0 1.2em;
    font-size: 1.25em;
    &:first-child {
      margin-top: 0;
    }
  }

  h2 {
    clear: both;
    font-weight: bold;
    line-height: 1.4;
    margin: 2em 0 1em;
    font-size: 1.25em;
    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    clear: both;
    font-weight: bold;
    line-height: 1.4;
    margin: 1.6em 0 .8em;
    font-size: 1.2em;
    &:first-child {
      margin-top: 0;
    }
  }

  table {
    max-width: 100%;
    margin: 1em 0 1.4em;
    border-collapse: collapse;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
  }
  th {
    font-size: .9em;
    padding: .4em;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    text-align: center;
  }
  td {
    font-size: .9em;
    padding: .4em;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }

  img {
    width: auto;
    max-width: 100%;
    height: auto!important;
  }

  iframe {
    max-width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    @media print, screen and (max-width: 760px) {
      width: 100%;
    }
  }

  video {
    max-width: 100%;
  }


  .photo01,
  .photo02 {
    margin-bottom: 20px;
  }

  .photo01 img {
    display: block;
    width: 100%;
    height: auto;
  }

  .photo02 {
    *zoom: 1;
  }

  .photo02:after {
    font-size: 0;
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: " ";
  }

  .photo02 li {
    overflow: hidden;
    width: 49%;
  }

  .photo02 li:first-child {
    float: left;
  }

  .photo02 li:last-child {
    float: right;
  }

  .photo02 li img {
    display: block;
    height: auto;
  }

  .unit_faq {
    margin-bottom: 50px;
  }

  .unit_faq dt,
  .unit_faq dd {
    font-size: 14px;
    line-height: 2em;
  }

  .unit_faq dt {
    font-size: 15px;
    font-weight: bold;
  }

  .unit_faq dd:last-child {
    margin-bottom: 0;
  }

  .unit_data table {
    width: 100%;

    border-collapse: collapse;

    border-top: 1px #ccc solid;
  }

  .unit_data tr {
    border-bottom: 1px #ccc solid;
  }

  .unit_data td {
    font-size: 13px;

    padding: 16px 0;
  }

  .unit_data th {
    font-size: 13px;
    font-weight: bold;

    width: 240px;
    padding: 16px;

    text-align: left;

    background: #f7f2ee;
  }

  .unit_data td {
    padding: 16px 32px;
  }




  &::after {
    content: "";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both;
  }

}
