@charset "utf-8";

@use "../_colors";
@use "../_text";


.page-system.page-system-index.page-welfare {
  .list-page__main {
    @media print, screen and (min-width:761px){
      width: 1100px;
    }
  }
  .p-item__photo.ph_sys,
  .ph.ph_sys {
    line-height: 0;
    overflow:hidden;
    img {
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: cover;
    }
  }

  .l-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    .p-item {
      width: 30%;
      margin-right: 5%;
      float: none;
      border: none;
      margin-bottom: 40px;
      margin-left: 0;
      @media screen and (max-width: 760px){
        width: 48%;
        margin-right: 4%;
        margin-bottom: 8%;
      }
      a {
        padding: 0;
      }
      &:nth-child(3n) {
        @media print, screen and (min-width:761px){
          margin-right: 0;
        }
      }
      &:nth-child(2n) {
        @media screen and (max-width: 760px){
          margin-right: 0;
        }
      }
      &:last-child {
        @media screen and (max-width: 760px){
          margin-left: 0;
        }
      }
      &__photo {
        width: 100%;
        height: 0;
        overflow: hidden;
        float: none;
        position: relative;
        padding-bottom: 100%;
        margin-bottom: 4%;
      }
      &__inner {
        float: none;
        width: 100%;
        text-align: left;
      }
      &__detail{
        display: none;
      }
    }
  }
  .featherlight-content{
    padding: 30px 25px 0;
    border-bottom: 30px solid transparent;
    overflow: inherit;
    @media screen and (max-width: 760px){
      height: 60vh;
      padding: 40px 10px 0;
      border-bottom: 40px solid transparent;
    }
    .featherlight-close-icon{
      top: 10px;
      right: 10px;
    }
    .featherlight-inner{
      overflow-y: scroll;
      height: 100%;
    }
  }
  .l-contents{
    .title{
      color: colors.$dark-text;
      font-size: 18px;
      line-height: 1.4;
      margin-bottom: 28px;
      font-weight: 700;
      @media screen and (max-width: 760px){
        font-size: 4.4vw;
        line-height: 1.4;
        margin-top: 3%;
        margin-bottom: 5%;
      }
    }
    .system_article p{
      color: colors.$dark-text;
    }
  }
  .other-detail{
    color: colors.$dark-text;
    .prev, .next{
      position: absolute;
      bottom: -30px;
      @media screen and (max-width: 760px){
        bottom: -28px;
      }
      span{
        display: block;
        width: 20px;
        height: 20px;
        @media screen and (max-width: 760px){
          width: 14px;
          height: 14px;
        }
      }
    }
    .prev{
      left: 20px;
      span{
        border-bottom: 2px solid colors.$gray;
        border-left: 2px solid colors.$gray;
        transform: rotate(45deg);
      }
    }
    .next{
      right: 20px;
      span{
        border-top: 2px solid colors.$gray;
        border-right: 2px solid colors.$gray;
        transform: rotate(45deg);
      }
    }
  }
  .section_other_article {
    margin-bottom: 100px;
    @media screen and (max-width: 760px){
      margin-bottom: 20%;
    }
  }
  /*  Main と Side
  *---------------------------------------------------------- */
  @media print, screen and (min-width:761px){
    /* for pc */

  }
  @media screen and (max-width: 979px) {
    /* for sp iPad */

  }
  @media screen and (max-width: 480px) {
    /* for sp Device Only */

  }


}/* end -- .page-system.l-news */


